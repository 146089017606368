@font-face {
  font-family: Rumiko-Regular;
  src: url('./assets/Font/RumikoClearDemo-Regular.otf');
}
@font-face {
  font-family: Rumiko-Regular-Italic;
  src: url('./assets/Font/RumikoClearDemo-RegularItalic.otf');
}
@font-face {
  font-family: Rumiko-Medium;
  src: url('./assets/Font/RumikoClearDemo-Medium.otf');
}
@font-face {
  font-family: Rumiko-Medium-Italic;
  src: url('./assets/Font/RumikoClearDemo-MediumItalic.otf');
}

body{
  font-family: Rumiko-Regular!important;
  background-color: #e0e0e0!important;
  margin: 0;
  padding: 0;
}

.bg-primary-color{
  background-color: #19143a;
}

main {
  width: 720px;
  margin: auto;
}

.btn-stp-danger{
  width: 50%;
  height: 50px;
  border-radius: 100px!important;
  font-size: 22px!important;
  letter-spacing: 2px;
  margin-top: 50px;
}

.my-default-temp{
  margin-top: 100px!important;
  margin-bottom: 100px!important;
}

@media (max-width: 767px) {
  main {
    width: 100%!important;
    margin: auto;
  }
  .my-default-temp{
    margin-top: 20px!important;
    margin-bottom: 20px!important;  
  }
  .btn-stp-danger{
    width: 100%;
    height: 50px;
    border-radius: 100px!important;
    margin-bottom: 50px;
    font-size: 16px!important;
    letter-spacing: 1px;
    margin-top: 0px;
  }
  .text-xs-center{
    text-align: center;
  } 
  .my-xs-5{
    margin-top: 20px;
    margin-bottom: 20px;
  }
}